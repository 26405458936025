import React, { useContext, useEffect, useState } from 'react';
import './RoomPage.scss';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getBookingsUsers, setAvailableTimeList } from '../redux/booking';
import FullscreenButton from '../components/FullscreenButton';
import SliderRange from '../components/SliderRange';
import { isAfter, set } from 'date-fns';
import { DeviceAuthContext } from '@inspace-org/react-auth';
import { PATH_APP } from '../routes';
import { useNavigate } from 'react-router-dom';
import { setBookDay } from '../redux/date';
import useDefaultBookingTime from '../hooks/useDefaultBookingTime';
import { getAvailableTime } from '../services/utils/date';
import { sendActionLogs } from '../services/utils/logs';

const RoomPage = ({ isAvailable }) => {
  const navigate = useNavigate();
  const { maxBookingDate, getStartWorkDay, getEndWorkDay } = useDefaultBookingTime();
  const { device, companySettings, settings } = useContext(DeviceAuthContext);
  const dispatch = useDispatch();
  const [currentBooking, setCurrentBooking] = useState('');

  const redux = useSelector((state) => ({
    placeInfo: state.place.info,
    bookDay: state.date.bookDay,
    isLoading: state.booking.isLoading,
    bookingList: state.booking.bookingList,
    workDayInHours: state.settings.workDayInHours,
    errors: state.booking.errors,
  }));

  const handleClick = async (e) => {
    sendActionLogs({
      placeInfo: redux.placeInfo,
      errors: redux.errors,
      device,
      companySettings,
      settings,
      actionName: 'Click to Reserve button',
      actionPath: window.location.pathname,
    });
    navigate(PATH_APP.users);
  };

  useEffect(() => {
    const startDay = getStartWorkDay(redux.bookDay);
    const endDay = getEndWorkDay(redux.bookDay);
    dispatch(setAvailableTimeList(getAvailableTime(redux.bookingList, redux.bookDay, startDay, endDay)));
  }, [redux.bookingList]);

  const getBookings = async (bookDate) => {
    try {
      if (device.floor && device.place) {
        const newBookDay = bookDate ? bookDate : redux.bookDay;
        await dispatch(
          getBookingsUsers({
            floorId: device.floor,
            placeId: device.place,
            workDayInHours: redux.workDayInHours,
            params: {
              bookDate: newBookDay,
              startTime: set(new Date(newBookDay), { hours: 0, minutes: 0, seconds: 5, milliseconds: 0 }).toISOString(),
              endTime: set(new Date(newBookDay), {
                hours: 23,
                minutes: 59,
                seconds: 55,
                milliseconds: 0,
              }).toISOString(),
            },
          }),
        );
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateBookings = () => {
    try {
      if (new Date().getDate() !== redux.bookDay.getDate()) {
        dispatch(setBookDay(new Date()));
        getBookings(new Date());
      } else {
        getBookings();
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (redux.workDayInHours) {
      getBookings();
    }
  }, [redux.workDayInHours, redux.bookDay]);

  useEffect(() => {
    const findEl = redux.bookingList.find(
      (el) =>
        new Date().toString() >= new Date(el.startTime).toString() &&
        new Date().toString() <= new Date(el.endTime).toString(),
    );
    if (!isAvailable && findEl) {
      setCurrentBooking(findEl);
    }
  }, [isAvailable]);

  useEffect(() => {
    const interval = setInterval(() => updateBookings(), 60000);
    return () => clearInterval(interval);
  }, [redux.workDayInHours]);

  return (
    <div className="content">
      <div className="content_list">
        <div className="content_item">
          <div className="content_status">
            {currentBooking?.visibility?.details !== 'private' ? (
              <h1 className="status">
                {isAvailable ? <Trans i18nKey="available_status" /> : <Trans i18nKey="booked_status" />}
              </h1>
            ) : (
              ''
            )}
          </div>
          <div className="content_title">
            <Trans
              i18nKey="desk_title"
              values={{
                number: redux.placeInfo.number,
              }}
            />
          </div>
          {isAvailable && !isAfter(new Date(), maxBookingDate) ? (
            <button className="button" onClick={handleClick}>
              <Trans i18nKey="reserve_btn" />
            </button>
          ) : (
            <>
              {currentBooking?.visibility?.details === 'private' ? (
                <div className="private">
                  <Trans i18nKey="event_private" />
                </div>
              ) : (
                <div>
                  <div className="name">
                    {`${currentBooking?.userEntity?.firstName} ${currentBooking?.userEntity?.lastName}`}
                  </div>
                  <div className="position">{currentBooking?.userEntity?.position}</div>
                  <div className="pronouns">{currentBooking?.userEntity?.pronouns}</div>
                </div>
              )}
            </>
          )}
        </div>
        {isAvailable && (
          <div className="content_item">
            <SliderRange isOnlyDisplayTime={true} />
          </div>
        )}
      </div>
      <div className="content_footer footer">
        <div className="footer_item">
          <FullscreenButton
            device={device}
            companySettings={companySettings}
            settings={settings}
            placeInfo={redux.placeInfo}
          />
        </div>
        <div className="footer_item">
          <p className="title">
            <Trans
              i18nKey="desk_title"
              values={{
                zone: redux.placeInfo.zoneName,
                number: redux.placeInfo.number,
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default RoomPage;
